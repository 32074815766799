import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Container, Image, Flex, Text, Button } from "src/styled"
import emailVerify from "src/assets/images/email-verify.png"
import { devices } from "src/theme/device"
import styled from "styled-components"
import { checkEmailVerification } from "src/redux/user"
import { navigate } from "@reach/router"

const StyledImage = styled(Image)`
    width: 30%;
    ${devices.tablet} {
        width: 80%;
    }
`

const Wrapper = styled(Flex)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
`

export default function Verify() {
    const { userData, isVerified, isAuthenticated } = useSelector(
        state => state.user
    )
    const dispatch = useDispatch()

    function onRefresh() {
        dispatch(checkEmailVerification())

        if (isVerified || !isAuthenticated) {
            navigate("/")
        }
    }

    return (
        <Container>
            <Wrapper>
                <StyledImage src={emailVerify} />
                <Text fontSize={23} color="primary">
                    Potwierdź swój adres mailowy: {userData.email}
                </Text>
                <Text>Odśwież stronę, jesli email został zweryfikowany.</Text>
                <br />
                <Button onClick={onRefresh} variant="primary">
                    Refresh
                </Button>
            </Wrapper>
        </Container>
    )
}
